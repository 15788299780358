import axios from 'axios';
const baseURL = `${process.env.REACT_APP_UPVOTE_API}api`;

console.log(baseURL);

export const setVote = (id: string, vote: string, path:string) => axios.post(`${baseURL}/vote/${id}`, { path, vote });

//AUTH
// export const signUpUser = ( user ) => axios.post(`${baseURL}/auth/signup`, user);
// export const signInUser = ( user ) => axios.post(`${baseURL}/auth/signin`, user);
// export const onBoardUser = ( user ) => axios.put(`${baseURL}/auth/onboard`, user);
export const getWidget = (id: string, path:string) => axios.post(`${baseURL}/widget/${id}`,{ path });
export const getVote = (id: number) => axios.get(`${baseURL}/vote/${id}`);

//VERIFY
export const verify = ( token:string ) => axios.get(`${baseURL}/auth/verify`,{headers:{Authorization: `Bearer ${token}`}});