import { Route, Routes } from "react-router-dom";
import { Widget } from "./dashboard/widgets/Widget";


const  App:React.FC = () => {
  return (
    <div className="upvote-app">

      <Routes>
        <Route path="/widget/:id" element={<Widget />} />
      </Routes>

    </div>
  );
}

export default App;
