import { Header, Stack, Text, Title } from '@mantine/core'
import React from 'react'

export const EmptyWidget = () => {
  return (
    <Stack
        justify='center'
        spacing='xs'
        align="center"
        >
        <Title order={5}>Widget not found</Title>
        <Text size='sm'>There is something wrong with your widget. Need help?</Text>
    </Stack> 
  )
}
