import React from 'react'
import { Button } from '@mantine/core';

interface ButtonInferface {
    onClick: () => void;
    children?: any;
    active: boolean;
}

export const Vote:React.FC<ButtonInferface> = ( { onClick, children, active } ) => {

    return (
        <Button
            variant={ !active ? 'default' : 'filled' }
            onClick={ onClick }>
            {children}
        </Button>
    )
}
