import { LoadingOverlay, Stack, Text } from '@mantine/core';
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getWidget, setVote } from '../../api/UpvoteApi';
import { Voting } from '../../components/buttons/Voting';
import { VoteInterface } from '../../interfaces/VoteInterface';
import { EmptyWidget } from './EmptyWidget';

const reducer = (state: VoteInterface, action: any) => {

    const { type, vote, up, down } = action;

    switch ( type ) {
        case 'up':
            return {
                up:1,
                down:0,
                vote: vote,
                type: type
            }
        case 'down':
            return {
                up:0,
                down:1,
                vote: vote,
                type: type
            }
        case 'reset':
            return {
                up,
                down,
                vote: vote,
                type: type
            }
        default:
            return state
    }
}


export const Widget:React.FC = () => {

    const { id } = useParams<string>();
    const isMounted = useRef<any>();

    const voteState:VoteInterface = { 
        up: 0,
        down: 0,
        vote: '',
        type: 'up'
    }

    const [widget, setWidget] = useState<any>(null);
    const [path, setPath] = useState<any>(null);
    const [onVote, dispatch] = useReducer(reducer, voteState);
    const [url, setUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    const updateVote = async (vote:any) => {
        try{
            if(onVote.vote !== vote && id) {
                dispatch(vote);
                await setVote(id, vote.type, path.path);
            }
        }catch(err){
            console.log(err);
        }
        
    }

    useEffect(() => {

        if( !isMounted.current ) {

            isMounted.current = true;
            
            (async () => {
                try{
                    
                    var currentURL = (window.location != window.parent.location)
                        ? document.referrer
                        : document.location.href;

                    setUrl(currentURL);

                    if(id){

                        const { data } = await getWidget(id, currentURL);
                        const { widget, path, vote } = data;
                        setPath(path);
                        setWidget(widget);
                        dispatch({ type: 'reset',vote: vote.vote, up: path.up, down: path.down});
                        
                    }
                    
                    
                }catch(err){
                    console.log(err);
                }finally{
                    setLoading(false);
                }
            })();
        }

    },[]);
    return (
        <>
            { loading && <>
                <div style={{ width: 400, position: 'relative' }}>
                    <LoadingOverlay visible={loading} />
                </div>
            </> }
            { !loading && !widget && !id && <>
                <EmptyWidget />
            </>}
            { !loading && widget && path && <>
                <Stack
                    justify='center'
                    spacing='sm'
                    align="center"
                    >
                    <Voting dispatch={ (action) => updateVote(action)} vote={onVote.vote}/>
                    <Text size='xs'>{onVote.up} out of { onVote.up + onVote.down } found this interesting.</Text>
                </Stack> 
            </>}
        </>
        
    )
}
