import { Group } from '@mantine/core';
import React from 'react'
import { Vote } from './Vote'

interface VoteInterface {
    dispatch: (action: object) => void;
    vote: string;
}

export const Voting:React.FC<VoteInterface> = ({ dispatch, vote }) => {
  return (
    <Group spacing="sm">
        <Vote onClick={ () => dispatch({ vote:'up', type:'up' }) } active={ vote === 'up' } >Yes</Vote>
        <Vote onClick={ () =>  dispatch({ vote:'down', type:'down' }) } active={ vote === 'down' } >No</Vote>
    </Group>
  )
}
